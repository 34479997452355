import React, { useEffect, useState } from "react";
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";

function CALC({ matchDetails, handleCloseModal, betModal, betSlipData, handlePlaceBet, setCount, lodingBet, count, handleOdds, setHandleOdds, profitLoss, setProfitLoss }) {

    const calculateProfitLoss = () => {
        const profitLossArray = {}; 
        if (betSlipData?.matchDetails?.runner_json.length > 0) {
            betSlipData?.matchDetails?.runner_json.forEach((element) => {
                let profitLoss = 0;

                if (element?.selectionId === betSlipData.selection_id && betSlipData.is_back === "1") {
                    profitLoss = (betSlipData.odds > 1) ? count * (betSlipData.odds - 1) : count * betSlipData.odds;
                } else if (element?.selectionId === betSlipData.selection_id && betSlipData.is_back === "0") {
                    profitLoss = (betSlipData.odds > 1) ? -1 * count * (betSlipData.odds - 1) : -1 * count * betSlipData.odds;
                } else if (element?.selectionId !== betSlipData.selection_id && betSlipData.is_back === "1") {
                    profitLoss = -1 * count;
                } else if (element?.selectionId !== betSlipData.selection_id && betSlipData.is_back === "0") {
                    profitLoss = count;
                }

                if (!profitLossArray[element.selectionId]) {
                    profitLossArray[element.selectionId] = 0;
                }
                profitLossArray[element.selectionId] += profitLoss;
            });
        }

        setProfitLoss(profitLossArray);
    };

    useEffect(() => {
        calculateProfitLoss();
    }, [matchDetails, betSlipData, count]);

    const incrementOdds = () => {
        if (handleOdds > 0) {
            setHandleOdds(prevValue => {
                const updatedValue = (prevValue + 0.01).toFixed(2);
                return parseFloat(updatedValue);
            });
        }
    };

    const decrementOdds = () => {
        if (handleOdds > 0) {
            setHandleOdds(prevValue => {
                const updatedValue = (prevValue - 0.01).toFixed(2);
                return parseFloat(updatedValue);
            });
        }
    };

    const handleButtonClick = (value) => {
        setCount(value);
    };

    const handleInputChange = (event) => {
        const value = parseInt(event.target.value) || 0; // Default to 0 if input is empty
        setCount(value);
    };

    const [matchStack, setMatchStack] = useState([]);

    useEffect(() => {
        let storedMatchStack = localStorage.getItem('match_stack');
        if (!storedMatchStack) {
            storedMatchStack = "100,500,1000,5000,10000,50000,100000";
            localStorage.setItem('match_stack', storedMatchStack);
        }
        const matchStackArray = storedMatchStack.split(',');
        setMatchStack(matchStackArray);
    }, []);

    return (
        <div className={`${betSlipData?.is_back === "1" ? "cals-data" : "cals-data-second"}`}>
            <div className="cals-databtn d-flex flex-wrap justify-content-center">
                <div className="cancel-btn hidecalc-btn">
                    <button className="text-capitalize" onClick={() => handleCloseModal()}>cancel</button>
                </div>
                <div className="input-calc d-flex flex-warp">
                    <div className="calcinput-data overflow-hidden">
                        <button onClick={decrementOdds}><FaMinus /></button>
                        <input type="number" value={handleOdds} readOnly />
                        <button onClick={incrementOdds}><FaPlus /></button>
                    </div>
                    <div className="calcinput-data overflow-hidden">
                        <button><FaMinus /></button>
                        <input
                            type="number"
                            value={count}
                            onChange={handleInputChange}
                        />
                        <button><FaPlus /></button>
                    </div>
                </div>

                <div className="place-btn-calc hidecalc-btn">
                    <button className="text-capitalize" onClick={() => handlePlaceBet()}>place bet</button>
                </div>
            </div>
            <div className="calcall-btn mt-2">
                <ul className="d-flex flex-wrap justify-content-center">
                    {matchStack.map((amount) => (
                        <li key={amount}>
                            <button className="w-100" onClick={() => handleButtonClick(amount)}>
                                {amount}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="d-md-none d-block">
                <div className="d-flex flex-wrap">
                    <div className="cancel-btn">
                        <button className="text-capitalize" onClick={() => handleCloseModal()}>cancel</button>
                    </div>
                    <div className="place-btn-calc">
                        <button className="text-capitalize" onClick={() => handlePlaceBet()}>place bet</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CALC;
